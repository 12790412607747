import * as Calc from '@mulliganfunding/loan-calculator'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import adBiggerDeals from '../../assets/imgs/ad-biggerdeals-1140x86.gif'
import arrowDown from '../../assets/imgs/chevron-down.svg'
import arrowUp from '../../assets/imgs/chevron-up.svg'
import circleArrowUp from '../../assets/imgs/circle-up-arrow.svg'
import './iso-calculator.css'

import {
  OfferType,
  PaymentFrequency,
  PricingTier,
} from '@mulliganfunding/api-partner-portal'
import { useAtom, useSetAtom } from 'jotai'
import {
  Card,
  CardInputSlider,
  ErrorModal,
  FooterNoteNode,
  ItemCard,
  MerchantCard,
  Page,
  SubmitButton,
} from '../../components'
import {
  AcceptedOfferSelect,
  DAYS_IN_WEEK,
  DEFAULT_ELITE_MONTH_MINIMUM,
  DEFAULT_PREMIERE_MONTH_MINIMUM,
  FUNDING_AMT_MIN,
  PendingOfferStatus,
  PendingOfferTemp,
  POST_HEADERS_JSON,
} from '../../constants'
import {
  adjustedCommissionDisplayState,
  buyRateState,
  calcNumsLoadedState,
  calcPaymentFrequencyState,
  commissionRateState,
  commSubSliderValueState,
  isoContributionState,
  isPageLoadingState,
  loadSavedCalcState,
  loanAmountState,
  loanPaymentFrequencyOptionsState,
  loanSpecialistState,
  maxFundingAmountState,
  merchantNameState,
  monthMaxState,
  netFundingAmountState,
  originationRateState,
  originationSliderMinState,
  pendingOfferState,
  requirementListState,
  selectedInterestForgivenessState,
  selectedMonthMinState,
  selectedTierState,
  stateCalcNums,
  tierInterestForgivenessListState,
  tierListState,
} from '../../state'
import {
  decimalToPercent,
  format$,
  formatValue,
  hideZeroCents,
  logColor,
  percentToDecimal,
  roundToNearestHundreth,
  roundToNearestQuarter,
  roundToNearestTenThousandth,
  scrollToTop,
} from '../../utils'

export const IsoCalculatorPage = () => {
  const setIsLoading = useSetAtom(isPageLoadingState)

  // Calcnums
  const [calcNums, setCalcNums] = useAtom(stateCalcNums)
  const [recalculateCalcNums, setRecalculateCalcNums] = useState(false)
  const [loadSavedCalc, setLoadSavedCalc] = useAtom(loadSavedCalcState)

  //UI display variables
  const [adjustedCommissionDisplay, setAdjustedCommissionDisplay] = useAtom(
    adjustedCommissionDisplayState,
  )

  const setOriginationSliderMin = useSetAtom(originationSliderMinState)
  const [originationSliderChanged, setOriginationSliderChanged] =
    useState(false)
  const [buyRateAdjusted, setBuyRateAdjusted] = useState(false)
  const [isRTFOpen, setIsRTFOpen] = useState(false)
  const [isDisplayErrorModal, setIsDisplayErrorModal] = useState<boolean>(false)

  //Loan Form Variables
  const [netFundingAmount, setNetFundingAmount] = useAtom(netFundingAmountState)
  const [maxFundingAmount, setMaxFundingAmount] = useAtom(maxFundingAmountState)
  const [minFundingAmount, setMinFundingAmount] = useState(0)

  const [loanAmount, setLoanAmount] = useAtom(loanAmountState)
  const [commissionRate, setCommissionRate] = useAtom(commissionRateState)
  const [originationRate, setOriginationRate] = useAtom(originationRateState)
  const [buyRate, setBuyRate] = useAtom(buyRateState)
  const [isoContribution, setIsoContribution] = useAtom(isoContributionState)
  const [calcPaymentFrequency, setCalcPaymentFrequency] = useAtom(
    calcPaymentFrequencyState,
  )

  const [commSubSliderValue, setCommSubSliderValue] = useAtom(
    commSubSliderValueState,
  )

  const [paymentFrequency, setpaymentFrequency] =
    useState<PaymentFrequency>(calcPaymentFrequency)

  const [originalMaxFundingAmount, setOriginalMaxFundingAmount] = useState(0)
  const [offerType] = useState<string>('')
  const [loanPaymentFrequencyOptions] = useAtom(
    loanPaymentFrequencyOptionsState,
  )

  const [requirementList] = useAtom(requirementListState)

  //Merchant Form Values
  const [merchantName] = useAtom(merchantNameState)
  const [loanSpecialist] = useAtom(loanSpecialistState)
  const [tierList] = useAtom(tierListState)
  const [tierInterestForgivenessList, setTierInterestForgivenessList] = useAtom(
    tierInterestForgivenessListState,
  )

  //Payload variables
  const [pendingOffer, setPendingOffer] = useAtom(pendingOfferState)
  const [calcNumsLoaded, setCalcNumsLoaded] = useAtom(calcNumsLoadedState)
  const [tierLoaded, setTierLoaded] = useState(false)
  const [selectedTier, setSelectedTier] = useAtom(selectedTierState)
  const [selectedTierName, setSelectedTierName] = useState<string>('')
  const [selectedInterestForgiveness, setSelectedInterestForgiveness] = useAtom(
    selectedInterestForgivenessState,
  )
  const [selectedMonthMin, setSelectedMonthMin] = useAtom(selectedMonthMinState)

  //Default Values
  const [monthMax] = useAtom(monthMaxState)
  const [defaultMonthMin, setDefaultMonthMin] = useState(0)

  //CalcNums Handlers
  const calculateOriginationFee = useCallback(
    (calc_nums: Calc.IBasicCalcData): number[] => {
      const orig_fee_percent = calc_nums.orig_fee_percent
      const orig_fee_dollars = Calc.roundDollars(
        (calc_nums.funding_amt * orig_fee_percent) / 100,
      )
      if (orig_fee_percent === 0 || !isFinite(orig_fee_dollars)) {
        return [0, 0]
      }
      return [orig_fee_dollars, orig_fee_percent]
    },
    [],
  )
  const calculateCalcNums = useCallback(
    (newCalcNums: Calc.IBasicCalcData): Calc.IBasicCalcData => {
      const termDays =
        newCalcNums.selectedTotalMonths * Calc.constants.DAYS_IN_MONTH
      const recalculatedCalcNums: Calc.IBasicCalcData = {
        ...newCalcNums,
        commission:
          newCalcNums.selectedCommissionPercent * newCalcNums.funding_amt,
        term_days: termDays,
        term_weeks: termDays / DAYS_IN_WEEK,
        total_factor:
          newCalcNums.selectedCommissionPercent + newCalcNums.buy_rate,
      }

      const calcNumsWithTotalRepayment: Calc.IBasicCalcData = {
        ...recalculatedCalcNums,
        total_repayment_amt: roundToNearestHundreth(
          Calc.loanCalcs.total_repayment_amt(recalculatedCalcNums),
        ),
      }
      const calcNumsAllPayment: Calc.IBasicCalcData = {
        ...calcNumsWithTotalRepayment,
        ...Calc.allPayments(calcNumsWithTotalRepayment),
      }

      const [origFeeDolls, origFeePercent]: number[] =
        calculateOriginationFee(calcNumsAllPayment)

      const calcNumsWithOrigination = {
        ...calcNumsAllPayment,
        ...{
          orig_fee_dollars: origFeeDolls,
          orig_fee_percent: origFeePercent,
        },
      }

      return calcNumsWithOrigination
    },
    [calculateOriginationFee],
  )

  const recalculateMaxFundingAmount = useCallback(
    (
      calcNumsToUpdate: Calc.IBasicCalcData,
      currentMFA: React.SetStateAction<number>,
    ): Calc.IBasicCalcData => {
      const calcNumsWithNewMFA = { ...calcNumsToUpdate }
      //let newCalcNums = calcNums
      // - funding amt cannot exceed max_funding_amt
      // - payment cannot exceed max payment
      // if funding-amt at `term_days` exceeds daily payment max, adjust down
      if (pendingOffer?.payment_daily_max) {
        const maxTotalRepmt =
          pendingOffer?.payment_daily_max * calcNumsToUpdate.term_days
        // round to nearest 100
        const mfa = Calc.bankersRounding(
          maxTotalRepmt / calcNumsToUpdate.total_factor,
          -2,
        )

        if (mfa < originalMaxFundingAmount) {
          currentMFA = mfa
        } else if (originalMaxFundingAmount) {
          currentMFA = originalMaxFundingAmount
        }

        setMaxFundingAmount(currentMFA)
        if (
          currentMFA !== undefined &&
          calcNumsToUpdate.funding_amt > currentMFA
        ) {
          calcNumsToUpdate.funding_amt = currentMFA

          // when we reset `funding_amt`, we must reset payments and total_repayment_amt
          return calculateCalcNums({
            ...calcNumsWithNewMFA,
            funding_amt: currentMFA,
          })
        }
      }

      return calcNumsToUpdate
    },
    [
      originalMaxFundingAmount,
      pendingOffer?.payment_daily_max,
      calculateCalcNums,
      setMaxFundingAmount,
    ],
  )

  const getSelectedCommission = useCallback(() => {
    const tierMaxMarkUp = percentToDecimal(selectedTier.max_markup)
    const selectedCommission =
      calcNums.selectedCommissionPercent &&
      calcNums.selectedCommissionPercent < tierMaxMarkUp
        ? calcNums.selectedCommissionPercent
        : tierMaxMarkUp
    setCommissionRate(selectedCommission)
    setCommSubSliderValue(decimalToPercent(selectedCommission))
    return selectedCommission
  }, [
    selectedTier?.max_markup,
    calcNums.selectedCommissionPercent,
    setCommissionRate,
    setCommSubSliderValue,
  ])

  const getLoanAmount = useCallback(() => {
    const offerLoanAmount = roundToNearestHundreth(pendingOffer.funding_amt_max)
    const fundingAmount =
      calcNums.funding_amt && calcNums.funding_amt < offerLoanAmount
        ? calcNums.funding_amt
        : offerLoanAmount
    logColor.purple('setLoanAmount: ', fundingAmount)
    setLoanAmount(fundingAmount)
    return fundingAmount
  }, [pendingOffer?.funding_amt_max, calcNums.funding_amt, setLoanAmount])

  const updateCalcNums = useCallback(() => {
    const defaultCalNums = {
      // Initialize various constants
      selectedCommissionPercent: getSelectedCommission(),
      commission: 0,
      orig_fee_percent: roundToNearestQuarter(originationRate),
      hero_eligible: false,
      // these are all ISO deals so no direct deal
      isNotDirect: true,

      // the `Calc` library buy_rate was giving us an unexpected result.
      buy_rate: buyRate,
      //max_markup: Calc.loanCalcs.max_markup(selectedTier, newCalcNums),
      channel: selectedTier.channel,
      pricing_term_name: selectedTier.name,
      max_payment_daily: pendingOffer.payment_weekly_max,
      max_payment_weekly: pendingOffer.payment_daily_max,
      payment_freq: calcPaymentFrequency,

      selectedTotalMonths: selectedTier.months,
      monthlySales: pendingOffer.monthly_sales,
      availableBalance: pendingOffer.available_balance,
      monthly_holdback_perc: pendingOffer.monthly_holdback_percent,
      merchantName: pendingOffer.merchant_name,
      relationshipManager: pendingOffer.owner,
      offerType: pendingOffer.offer_type,
      uuid: pendingOffer.uuid,
      term_days:
        pendingOffer.pricing_tier.months * Calc.constants.DAYS_IN_MONTH,
    }
    const calcNumsWithMaxMarkup = {
      ...defaultCalNums,
      max_markup: Calc.loanCalcs.max_markup(selectedTier, defaultCalNums),
    }

    const omfa = roundToNearestHundreth(pendingOffer.funding_amt_max)
    const calcNumsWithFunding = {
      ...calcNumsWithMaxMarkup,
      funding_amt: getLoanAmount(),
      selectedFundingAmount: omfa,
    }

    setMaxFundingAmount(omfa)
    setOriginalMaxFundingAmount(omfa)

    const calculatedCalcNums = calculateCalcNums(calcNumsWithFunding)
    const calcNumsWithMaxFunding = recalculateMaxFundingAmount(
      calculatedCalcNums,
      omfa,
    )
    setCalcNums({ ...calcNumsWithMaxFunding })
  }, [
    calcPaymentFrequency,
    getLoanAmount,
    getSelectedCommission,
    originationRate,
    buyRate,
    selectedTier,
    pendingOffer?.payment_weekly_max,
    pendingOffer?.payment_daily_max,
    pendingOffer?.monthly_sales,
    pendingOffer?.available_balance,
    pendingOffer?.monthly_holdback_percent,
    pendingOffer?.merchant_name,
    pendingOffer?.owner,
    pendingOffer?.offer_type,
    pendingOffer?.uuid,
    pendingOffer?.pricing_tier?.months,
    pendingOffer?.funding_amt_max,
    setMaxFundingAmount,
    calculateCalcNums,
    recalculateMaxFundingAmount,
    setCalcNums,
  ])
  const getFormValues = useCallback(() => {
    return {
      ...calcNums,
      ...{
        selectedCommissionPercent: commissionRate,
        orig_fee_percent: originationRate,
        buy_rate: buyRate,
        funding_amt: loanAmount,
        payment_freq: paymentFrequency,
      },
    }
  }, [
    buyRate,
    calcNums,
    commissionRate,
    loanAmount,
    originationRate,
    paymentFrequency,
  ])
  const updateCommSubSliderValue = useCallback(
    (newCommissionRate) => {
      const value = newCommissionRate + isoContribution
      setCommSubSliderValue(value)
    },
    [isoContribution, setCommSubSliderValue],
  )
  const recalculations = useCallback(() => {
    const maxFundingCopy = maxFundingAmount
    const calcNumsCopy = getFormValues()
    // calc total_factor, funding_amt, payments
    const calculatedCalcNums: Calc.IBasicCalcData =
      calculateCalcNums(calcNumsCopy)
    // adjust funding_amt downward if necessary
    const recalculatedCalcNums: Calc.IBasicCalcData =
      recalculateMaxFundingAmount(calculatedCalcNums, maxFundingCopy)
    setCalcNums(recalculatedCalcNums)
    setRecalculateCalcNums(false)
  }, [
    calculateCalcNums,
    getFormValues,
    maxFundingAmount,
    recalculateMaxFundingAmount,
    setCalcNums,
  ])

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    if (recalculateCalcNums && Object.keys(pendingOffer).length !== 0) {
      recalculations()
    }
  }, [recalculateCalcNums, pendingOffer, recalculations])

  useEffect(() => {
    setCalcNumsLoaded(true)
    setNetFundingAmount(calcNums.funding_amt - calcNums.orig_fee_dollars)
  }, [calcNums, setCalcNumsLoaded, setNetFundingAmount])

  //Selected Tier Event Handlers
  const updateSelectedTier = (
    tierName: string,
    interestForgiveness: string,
    month: number,
  ) => {
    logColor.green('updateSelectedTier', {
      tierName,
      interestForgiveness,
      month,
    })
    if (
      selectedTier.tier_basic_name.toLowerCase() !== tierName.toLowerCase() ||
      selectedTier.early_payoff_int_forgiveness.toString() !==
        interestForgiveness ||
      selectedTier.months !== month
    ) {
      const selectTier =
        tierList[tierName]?.interest_forgiveness[interestForgiveness]?.[month]
      if (selectTier) {
        setLoadSavedCalc(false)
        setSelectedTier(selectTier)
      }
    }
  }
  const handleTierChange = (tierName: string) => {
    logColor.lime('handleTierChange: ' + tierName)
    updateSelectedTier(tierName, selectedInterestForgiveness, monthMax)
  }
  const handleInterestForgivenessChange = (interestForgiveness: string) => {
    updateSelectedTier(
      selectedTierName,
      interestForgiveness,
      calcNums.selectedTotalMonths,
    )
  }
  const handleMonthChange = (month: number) => {
    logColor.green('handleMonthChange: ' + month)
    updateSelectedTier(selectedTierName, selectedInterestForgiveness, month)
  }
  const handlePaymentFrequencyChange = (frequency: PaymentFrequency) => {
    logColor.blue('handlePaymentFrequencyChange', { frequency })
    setCalcPaymentFrequency(frequency)
    setpaymentFrequency(frequency)
    setRecalculateCalcNums(true)
  }

  //Origination Fee Slider Calculations
  const initialCommissionRate = useMemo(() => {
    return commissionRate * 100 + isoContribution
  }, [commissionRate, isoContribution])
  const calculateOriginationFeeMax = useCallback(() => {
    const difference = selectedTier?.max_markup - selectedTier?.orig_fee_percent
    return difference > 0 ? 0 : Math.abs(difference)
  }, [selectedTier?.max_markup, selectedTier?.orig_fee_percent])

  const calculateCommissionRate = useCallback(() => {
    //console.log(`initialCommissionRate (${initialCommissionRate}) - (selectedTier.orig_fee_percent (${selectedTier.orig_fee_percent}) - originationRate (${originationRate})) `)
    const commission =
      initialCommissionRate -
      ((selectedTier?.orig_fee_percent || 0) - originationRate)
    const roundedCommission = roundToNearestQuarter(commission)

    const cr =
      !roundedCommission || roundedCommission < 0
        ? 0
        : Math.abs(roundedCommission)
    return percentToDecimal(cr)
  }, [initialCommissionRate, selectedTier?.orig_fee_percent, originationRate])
  const calculateBuyRate = useCallback(() => {
    //Current Buy rate = Initial buy rate + (Initial Origination Fee - Current Origination Fee)
    const br =
      selectedTier?.buy_rate +
      (percentToDecimal(selectedTier?.orig_fee_percent) -
        percentToDecimal(originationRate))
    const brMax = !br ? 0 : br

    return brMax
  }, [originationRate, selectedTier?.buy_rate, selectedTier?.orig_fee_percent])
  const calculateISOContribution = useCallback(() => {
    const contribution = roundToNearestQuarter(
      selectedTier?.orig_fee_percent - originationRate,
    )

    setIsoContribution(contribution)
    return contribution
  }, [originationRate, selectedTier?.orig_fee_percent, setIsoContribution])

  useEffect(() => {
    if (originationSliderChanged) {
      // the following was originally origFeeChangeCalculations logic
      // with some modifications
      calculateISOContribution()
      // the commission rate is calculated and set in handleOriginationFeeChange
      const br = calculateBuyRate()
      setBuyRate(br)
      setOriginationSliderChanged(false)
      setRecalculateCalcNums(true)
    }
  }, [
    calculateBuyRate,
    calculateCommissionRate,
    calculateISOContribution,
    commissionRate,
    setBuyRate,
    setCommissionRate,
    originationSliderChanged,
  ])

  //Loan Event Handlers
  const handleLoanAmountChange = (newLoanAmount) => {
    logColor.purple('handleLoanAmountChange setLoanAmount', newLoanAmount)
    setLoanAmount(newLoanAmount)
    setRecalculateCalcNums(true)
  }
  const handleCommissionRateChange = (newCommissionRate) => {
    updateCommSubSliderValue(newCommissionRate)
    setCommissionRate(percentToDecimal(newCommissionRate))
    setRecalculateCalcNums(true)
  }
  const handleOriginationFeeChange = (newOriginationFee) => {
    let origFeeUpdate = newOriginationFee

    const commissionRatePercent = decimalToPercent(commissionRate)

    // ensure origination fee reduction will not take commission percent below 0
    if (newOriginationFee < originationRate) {
      // if reducing the origination rate, limit the reduction to the remaining commissionRate
      // so commission does not drop below 0
      const originationRateChange = originationRate - newOriginationFee
      if (originationRateChange > commissionRatePercent) {
        // the proposed points traded is greater than the current commission rate, so adjust down
        origFeeUpdate = originationRate - commissionRate
      }
    }
    // adjust commission rate based on origin rate change
    const crChange = originationRate - origFeeUpdate
    const newCommissionRate = percentToDecimal(commissionRatePercent - crChange)
    if (origFeeUpdate !== originationRate) {
      // if orig rate changed, set new commission rate, orgin rate, and orig slider changed
      setCommissionRate(newCommissionRate)
      setOriginationRate(origFeeUpdate)
      setOriginationSliderChanged(true)
    }
  }

  const originDisableMin = useMemo(() => {
    const commissionPercent = decimalToPercent(
      calcNums.selectedCommissionPercent,
    )
    return originationRate > commissionPercent
      ? originationRate - commissionPercent
      : 0
  }, [calcNums.selectedCommissionPercent, originationRate])

  //Use effects to update UI components
  useEffect(() => {
    if (isoContribution > 0) {
      setAdjustedCommissionDisplay(
        `Trading origination points results in an adjusted commission total and buy rate.`,
      )
    } else {
      setAdjustedCommissionDisplay(null)
    }
  }, [isoContribution, setAdjustedCommissionDisplay])
  useEffect(() => {
    if (calcNums.selectedCommissionPercent <= 0) {
      setOriginationSliderMin(calcNums.orig_fee_percent)
    } else {
      setOriginationSliderMin(0)
    }
  }, [
    calcNums.orig_fee_percent,
    calcNums.selectedCommissionPercent,
    setOriginationSliderMin,
  ])
  useEffect(() => {
    const isBuyRateAdjusted = buyRate > selectedTier?.buy_rate ? true : false
    setBuyRateAdjusted(isBuyRateAdjusted)
  }, [buyRate, selectedTier?.buy_rate])
  const requirementListBlock = useMemo(() => {
    return (
      <div>
        {Array.isArray(requirementList) &&
          requirementList.map((requirement, index) => {
            return (
              <div
                className="req-to-fund-req-line"
                key={`req-to-fund-line-${index}`}
              >
                <span className="bullet">●</span> {requirement}
              </div>
            )
          })}
      </div>
    )
  }, [requirementList])

  //Handle payload and tier changes
  const setInterestForgivenessList = useCallback(
    (tierName: string) => {
      if (tierList?.[tierName]?.interest_forgiveness) {
        const ifl = Object.keys(tierList?.[tierName]?.interest_forgiveness)
        setTierInterestForgivenessList(ifl)
      }
    },
    [setTierInterestForgivenessList, tierList],
  )
  useEffect(() => {
    if (Object.keys(selectedTier).length !== 0) {
      const tierName = selectedTier.tier_basic_name?.toLocaleLowerCase()
      setSelectedTierName(tierName)

      if (!loadSavedCalc) {
        setOriginationRate(selectedTier.orig_fee_percent)
        setIsoContribution(0)
        setBuyRate(selectedTier.buy_rate)
      }

      const defaultMin =
        tierName == 'elite'
          ? DEFAULT_ELITE_MONTH_MINIMUM
          : DEFAULT_PREMIERE_MONTH_MINIMUM
      setDefaultMonthMin(defaultMin)

      const defaultFundingMin = pendingOffer?.funding_amt_min
        ? pendingOffer?.funding_amt_min
        : FUNDING_AMT_MIN
      setMinFundingAmount(defaultFundingMin)

      if (tierList) setInterestForgivenessList(tierName)

      const interestForgiveness =
        selectedTier.early_payoff_int_forgiveness.toString()
      setSelectedInterestForgiveness(interestForgiveness)
      setTierLoaded(true)
      setCalcNumsLoaded(false)
    }
  }, [
    loadSavedCalc,
    setCommissionRate,
    setOriginationRate,
    selectedTier,
    pendingOffer?.funding_amt_min,
    calculateOriginationFeeMax,
    setInterestForgivenessList,
    tierList,
    setSelectedInterestForgiveness,
    setIsoContribution,
    setCalcNumsLoaded,
    setBuyRate,
  ])
  useEffect(() => {
    const ifs =
      tierList[selectedTierName]?.interest_forgiveness[
        selectedInterestForgiveness
      ]

    if (ifs) {
      const months = Object.keys(ifs)
      const monthsAsNum = months.map(Number)
      const min = Math.min(...monthsAsNum)
      const monthMin = min < defaultMonthMin ? defaultMonthMin : min
      setSelectedMonthMin(monthMin)
    } else {
      setSelectedMonthMin(defaultMonthMin)
    }
  }, [
    defaultMonthMin,
    selectedInterestForgiveness,
    selectedTierName,
    setSelectedMonthMin,
    tierList,
  ])
  // update calc nums
  useEffect(() => {
    if (tierLoaded && !calcNumsLoaded) updateCalcNums()
  }, [calcNumsLoaded, updateCalcNums, tierLoaded])

  const onSubmit = async () => {
    const termDays = calcNums.selectedTotalMonths * 21 // TODO verify calc, check for function in calcs library
    const termWeeks = termDays / 5 // TODO verify calc, check for function in calcs library
    const acceptedOffer: AcceptedOfferSelect = {
      buy_rate: calcNums.buy_rate,
      commission_dollar_amt: calcNums.commission,
      commission_percentage: calcNums.selectedCommissionPercent,
      factor: calcNums.total_factor,
      frequency: calcNums.payment_freq,
      funding_amt: calcNums.funding_amt,
      // loanNetFundingAmount = funding amount - origination fee $
      loan_amt_net_origin: netFundingAmount,
      offer_type: offerType as OfferType,
      orig_fee_dollars: calcNums.orig_fee_dollars,
      orig_fee_percent: calcNums.orig_fee_percent,
      payment: calcNums.payment_amt,
      points_traded: isoContribution,
      sf_pricing_tier_id: selectedTier.sf_pricing_tier_id,
      term_days: termDays,
      term_months: calcNums.selectedTotalMonths,
      term_weeks: termWeeks,
      total_repayment: calcNums.total_repayment_amt,
      uuid: pendingOffer.uuid,
    }
    setIsLoading(true)
    const response = await fetch(`/api/partner-portal/calculator/select`, {
      method: 'POST',
      headers: POST_HEADERS_JSON,
      body: JSON.stringify(acceptedOffer),
    })
    if (response.ok) {
      const solidPendingOffer = pendingOffer as PendingOfferTemp
      const solidSelectedTier = selectedTier as PricingTier
      // selected offer accepted
      const status = PendingOfferStatus[PendingOfferStatus.PROVISIONAL_ACCEPTED]
      setPendingOffer({
        ...solidPendingOffer,
        accepted_offer: { ...acceptedOffer, pricing_tier: solidSelectedTier },
        status,
      })
    } else {
      // error response
      logColor.red(
        'ERROR: iso-calculator accept offer returned an error: ',
        response,
      )
      setIsDisplayErrorModal(true)
    }
    setIsLoading(false)
  }

  const currencyFormat = (num, pos) => {
    return '$' + num.toFixed(pos).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
    <>
      <ErrorModal
        isDisplay={isDisplayErrorModal}
        handleClose={() => setIsDisplayErrorModal(false)}
      />
      <Page
        title={'Offer Submission Calculator'}
        widthPx={1200}
        footerNoteNode={FooterNoteNode}
      >
        <div className="ad-banner-row">
          <img
            src={adBiggerDeals}
            alt={`loading icon`}
            width={'1140'}
            height={'86'}
          />
        </div>
        <div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="block">
              <div className="left-block">
                <MerchantCard
                  merchantName={merchantName}
                  tier={selectedTier}
                  tierList={Object.keys(tierList)}
                  interestForgivenessList={tierInterestForgivenessList}
                  interestForgiveness={selectedInterestForgiveness}
                  loanSpecialist={loanSpecialist}
                  handleInterestForgivenessChange={
                    handleInterestForgivenessChange
                  }
                  handleSelectedTierChange={handleTierChange}
                  paymentFrequency={calcNums.payment_freq}
                  handlePaymentFrequencyChange={handlePaymentFrequencyChange}
                  paymentFrequencyOptions={loanPaymentFrequencyOptions}
                />
                <Card>
                  <CardInputSlider
                    label={'Loan Amount'}
                    id={'loan-amount'}
                    max={maxFundingAmount}
                    min={minFundingAmount}
                    onValueChange={handleLoanAmountChange}
                    step={100}
                    value={calcNums.funding_amt}
                    symbol={'$'}
                    symbolBefore={true}
                    symbolInput={true}
                    dynamicStep={true}
                  />
                  <div className="rtf-block">
                    <div className="rtf-title-row">
                      <div className="rtf-title typog-body">
                        {`Requirements`}
                        <sup>1</sup>
                        {` to Fund (${requirementList?.length})`}
                      </div>
                      <div
                        className="arrow-icon-wrapper"
                        onClick={() => setIsRTFOpen(!isRTFOpen)}
                      >
                        <img
                          className="arrow-icon"
                          src={isRTFOpen ? arrowUp : arrowDown}
                          alt={
                            isRTFOpen
                              ? 'hide requirements to fund'
                              : 'show requirements to fund'
                          }
                        />
                      </div>
                    </div>
                    {!isRTFOpen ? null : (
                      <>
                        <div className="rtf-contents">
                          {requirementListBlock}
                        </div>
                        <div className="req-note-1">
                          Additional documentation could be required
                          <sup>1</sup>
                        </div>
                      </>
                    )}
                  </div>
                </Card>
                <Card>
                  <CardInputSlider
                    id={'term'}
                    label={'Term (Months)'}
                    max={pendingOffer?.term_months_max}
                    min={selectedMonthMin}
                    onValueChange={handleMonthChange}
                    step={1}
                    value={calcNums.selectedTotalMonths}
                    symbol={'mo.'}
                  />
                </Card>
                <Card>
                  <CardInputSlider
                    id={'commission'}
                    label={'Commission'}
                    max={decimalToPercent(calcNums.max_markup)}
                    min={0}
                    onValueChange={handleCommissionRateChange}
                    shadowedValue={`${currencyFormat(calcNums.commission, 0)}`}
                    step={0.25}
                    value={decimalToPercent(calcNums.selectedCommissionPercent)}
                    symbol={'%'}
                    symbolInput={true}
                    subSliderDiff={isoContribution}
                    subSliderValue={commSubSliderValue}
                    lock={selectedTier?.max_markup <= 0}
                    multiSlider
                  />
                </Card>
                <Card>
                  <CardInputSlider
                    id={'originationFee'}
                    label={'Origination Fee'}
                    max={selectedTier?.orig_fee_percent}
                    min={
                      selectedInterestForgiveness == '100'
                        ? selectedTier?.orig_fee_percent
                        : 0
                    }
                    onValueChange={handleOriginationFeeChange}
                    shadowedValue={`${currencyFormat(
                      calcNums.orig_fee_dollars,
                      0,
                    )}`}
                    step={0.25}
                    value={originationRate}
                    symbol={'%'}
                    symbolInput={true}
                    highlight
                    additionalInfo={adjustedCommissionDisplay}
                    toolTipPercent={isoContribution}
                    disableMin={originDisableMin}
                    lock={selectedTier?.orig_fee_percent <= 0}
                  />
                </Card>
              </div>
              <div className="right-block">
                <div className="right-block-contents">
                  <div className="section-heading typog-h4-small">
                    Offer Summary
                  </div>
                  <div className="repayment-block">
                    <div className="repayment-title typog-body-small-subtitle">{`TOTAL REPAYMENT`}</div>
                    <div className="repayment-amount">{`${currencyFormat(
                      calcNums.total_repayment_amt,
                      0,
                    )}`}</div>
                  </div>
                  <div className="line-block">
                    <div className="line-label typog-body">{`Buy Rate`}</div>
                    <div className="line-value typog-h4-small">
                      {buyRateAdjusted && (
                        <span>
                          <img
                            className="circle-arrow-icon"
                            src={circleArrowUp}
                            alt={`buy rate icon`}
                          />
                        </span>
                      )}
                      {roundToNearestTenThousandth(calcNums.buy_rate)}
                    </div>
                  </div>
                  <div className="line-block">
                    <div className="line-label">{`Factor`}</div>
                    <div className="line-value">{`${roundToNearestTenThousandth(
                      calcNums.total_factor,
                    )}`}</div>
                  </div>
                  <div className="line-block">
                    <div className="line-label">{`Payment`}</div>
                    <div className="line-value">{`${format$(
                      calcNums.payment_amt,
                      2,
                    )}`}</div>
                  </div>
                  <div className="horizontal-separator" />
                  <ItemCard
                    key={`paymentFrequency`}
                    label={`Payment Frequency`}
                    valueList={[calcNums.payment_freq]}
                  />
                  <ItemCard
                    key={`term`}
                    label={`Term`}
                    valueList={[`${calcNums.selectedTotalMonths} Months`]}
                  />
                  <ItemCard
                    key={`commission`}
                    label={`Commission`}
                    valueList={[
                      `${formatValue(
                        calcNums.selectedCommissionPercent * 100,
                        '%',
                      )}`,
                      `${currencyFormat(calcNums.commission, 0)}`,
                    ]}
                  />
                  <ItemCard
                    key={`orig-fee`}
                    label={`Origination Fee`}
                    valueList={[
                      `${formatValue(calcNums.orig_fee_percent, '%')}`,
                      `${currencyFormat(calcNums.orig_fee_dollars, 0)}`,
                    ]}
                    displaySubtext={isoContribution > 0}
                    subTextLabel={`Fee Traded`}
                    subTextValueList={[
                      `${isoContribution}%`,
                      format$(
                        percentToDecimal(isoContribution) *
                          calcNums.funding_amt,
                        0,
                      ), // TODO temp origin fee traded $
                    ]}
                  />

                  <ItemCard
                    key={`net-funding-amount`}
                    label={`Net Funding Amount`}
                    valueList={[hideZeroCents(format$(netFundingAmount, 2))]}
                  />
                  <SubmitButton onClick={onSubmit}>
                    SELECT THIS OFFER
                  </SubmitButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Page>
    </>
  )
}
